import Calculator from "../components/Home/calculator/Calculator";
import CalculatorDescription from "../components/Home/calculator/CalculatorDescription";
import FeaturedContent from "../components/Home/FeaturedContent/FeaturedContent";
import PageLayout from "../components/PageLayout";
import RegisterInterest from "../components/RegisterInterest";
import Home from '../styles/Home.css'

function HomePage() {
  return (
    <PageLayout title="Welcome to HustlePig" description="Discover the best side hustles and tips.">
      <div className="bg-slate-500 px-16 py-10 rounded-lg home-page">
        <p className="text-3xl text-center font-bold mb-5 text-orange-400">Turn Your Free Time into Money: Discover Side Hustles That Work for You!</p>
        <p className="text-xl text-center font-bold mb-5 text-white">Discover, learn, and kickstart your journey into the world of side hustles with HustlePig — your ultimate resource for turning spare time into extra income!</p>
        <p className="text-xl text-center font-bold mb-5 px-8 text-white">
          HustlePig is your go-to platform for discovering innovative side hustle ideas, tools, and strategies to maximize your earning potential. Whether you're looking to supplement your income or turn your passion into profit, we're here to guide you every step of the way.
          With curated resources, real-world tips, and powerful calculators, HustlePig empowers you to take control of your financial future — one hustle at a time.
        </p>

        <p className="text-2xl text-orange-400 font-bold text-center mt-12 italic link"><a href="#hustleCalculator">Try our Hustle Calculator</a></p>
      </div>
      <FeaturedContent />
      <div className="flex justify-between py-10 px-6 bg-slate-500 mt-16 rounded-lg">
        <div className="w-[48%] mt-8">
          <Calculator />
        </div>
        <CalculatorDescription />
      </div>

      {/* Disabling register interest temporarily due to legal considerations */}
      {/* <RegisterInterest /> */}
    </PageLayout>
  );
}

export default HomePage;

// Featured Content
// 💡 Top 10 Side Hustles for 2024
// Discover the most profitable and in-demand side gigs to kickstart your earning journey this year.

// 📊 Side Hustle Income Calculator
// Estimate your potential earnings and plan your goals with our intuitive calculator.

// 📖 Beginner’s Guide to Freelancing
// Learn how to get started, find clients, and scale your freelance business from scratch.

// 🎙️ Success Stories from Hustlers
// Be inspired by real-life stories of people turning their passions into thriving side hustles.

// Start exploring today and take the first step toward financial freedom with HustlePig!


