const colors = {
    primary: "#FF6600", // HustlePig brand orange
    secondary: "#333333", // Dark gray for text
    background: "#FFFFFF", // White for background
    accent: "#F9F9F9", // Subtle background accents
    error: "#FF0000", // Error red
    success: "#00FF00", // Success green 

    gray: "#1f2937",
    blue: "#2563eb"

  };
  
  export default colors;
  