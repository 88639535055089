import PageLayout from "../components/PageLayout";

function AboutPage() {
  return (
    <PageLayout title="About HustlePig" description="Learn more about HustlePig and what we do.">
      <p>This is the about page content.</p>
    </PageLayout>
  );
}

export default AboutPage;
