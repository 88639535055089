import React from 'react'

const CalculatorDescription = () => {
    return (
        <div className='w-[48%] text-white'>

            <div>
                <h3 className='text-3xl font-bold text-orange-400'>Side Hustle Income Calculator</h3>
                <div className='text-xl'>
                    <p className='mt-6'>
                        Our <strong>Side Hustle Income Calculator</strong> is a simple, intuitive tool designed to help you estimate
                        your potential monthly earnings from your side project or online business.
                    </p>
                    <h4>Features:</h4>
                    <ul>
                        <li><strong>Monthly Visitors</strong>: Enter the number of visitors your website or platform receives in a month.</li>
                        <li><strong>Conversion Rate</strong>: Provide your estimated conversion rate as a percentage (e.g., the percentage of visitors who make a purchase).</li>
                        <li><strong>Average Income per User</strong>: Input the average amount of income generated from each customer or user.</li>
                    </ul>
                    <p>
                        Once you fill out the fields and click the "Calculate" button, the tool will instantly calculate your
                        <strong> estimated monthly income</strong> and display the results in a clear, easy-to-read format. Whether you're
                        a beginner exploring side hustles or an entrepreneur projecting growth, this calculator offers a quick and
                        reliable estimate of your earning potential.
                    </p>
                </div>
            </div>

        </div>
    )
}

export default CalculatorDescription