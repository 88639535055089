import React from 'react'
import colors from '../../../styles/colors';
import { TITLE_1, TITLE_2, TITLE_3, TITLE_4, TITLE_5, TITLE_6, TITLE_7, TITLE_8, TITLE_9, TITLE_10 } from '../../../constants/FeaturedContentText';

const HustleList = () => {
    return (
        <div>
            <h2 className='text-center mt-10 text-3xl font-bold' style={{ color: colors.primary }}>Top 10 hottest hustles right now</h2>
            <div className='md:w-full mx-auto bg-slate-300 px-20 py-2 rounded-lg mt-8'>
                <div className='flex flex-row justify-between my-10 hustle-list-container'>
                    <ol>
                        <li><a href='#blog1'>1: {TITLE_1}</a></li>
                        <li><a href='#blog2' className='text-wrap'>2: {TITLE_2}</a></li>
                        <li><a href='#blog3'>3: {TITLE_3}</a></li>
                        <li><a href='#blog4'>4: {TITLE_4}</a></li>
                        <li><a href='#blog5'>5: {TITLE_5}</a></li>
                    </ol>
                    <ol>
                        <li><a href='#blog6'>6: {TITLE_6}</a></li>
                        <li><a href='#blog7'>7: {TITLE_7}</a></li>
                        <li><a href='#blog8'>8: {TITLE_8}</a></li>
                        <li><a href='#blog9'>9: {TITLE_9}</a></li>
                        <li><a href='#blog10'>10: {TITLE_10}</a></li>
                    </ol>
                </div>
            </div>
        </div>
    )
}

export default HustleList;

// TODO create anchors