import { useState } from "react";

const Calculator = () => {
  const [visitors, setVisitors] = useState("");
  const [conversionRate, setConversionRate] = useState("");
  const [incomePerUser, setIncomePerUser] = useState("");
  const [result, setResult] = useState(0);

  const calculateIncome = () => {
    const visitorsNum = parseFloat(visitors);
    const conversionRateNum = parseFloat(conversionRate) / 100;
    const incomePerUserNum = parseFloat(incomePerUser);

    if (!isNaN(visitorsNum) && !isNaN(conversionRateNum) && !isNaN(incomePerUserNum)) {
      const totalIncome = visitorsNum * conversionRateNum * incomePerUserNum;
      setResult(totalIncome.toFixed(2));
    } else {
      setResult(0);
    }
  };

  return (
    <div className="w-full mx-auto p-4 bg-white rounded shadow-lg border border-slate-200" id="hustleCalculator">
      <h2 className="text-2xl font-bold mb-4">Side Hustle Income Calculator</h2>
      <div className="space-y-4">
        <div>
          <label className="block text-gray-700">Monthly Visitors</label>
          <input
            type="number"
            value={visitors}
            onChange={(e) => setVisitors(e.target.value)}
            className="w-full p-2 border rounded"
          />
        </div>
        <div>
          <label className="block text-gray-700">Conversion Rate (%)</label>
          <input
            type="number"
            value={conversionRate}
            onChange={(e) => setConversionRate(e.target.value)}
            className="w-full p-2 border rounded"
          />
        </div>
        <div>
          <label className="block text-gray-700">Average Income per User (£)</label>
          <input
            type="number"
            value={incomePerUser}
            onChange={(e) => setIncomePerUser(e.target.value)}
            className="w-full p-2 border rounded"
          />
        </div>
        <button
          onClick={calculateIncome}
          className="w-full bg-blue-600 text-white py-2 rounded hover:bg-blue-700"
        >
          Calculate
        </button>
      </div>
      <div className="mt-4 text-center">
        <h3 className="text-xl font-semibold">Estimated Monthly Income:</h3>
        <p className="text-2xl font-bold text-green-600">£{result}</p>
      </div>
    </div>
  );
};

export default Calculator;
