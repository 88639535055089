import React from 'react'
import HustleList from './HustleList';
import DetailedHustle from './DetailedHustle'
import {
  BLOG_TEXT,
  YOUTUBE_TEXT,
  ONLINE_SURVEYS,
  SELL_WEBSITES,
  TEST_APPS,
  FREELANCE_WORK,
  SELL_PHOTOGRAPHY,
  TEACH_ONLINE,
  RECORD_PODCAST,
  DIGITAL_PRODUCTS,
  TITLE_1,
  TITLE_10,
  TITLE_2,
  TITLE_3,
  TITLE_4,
  TITLE_5,
  TITLE_6,
  TITLE_7,
  TITLE_8,
  TITLE_9

} from '../../../constants/FeaturedContentText';

// TODO add ids for anchors
const detailedData = [
  { title: TITLE_1, paragraph: BLOG_TEXT, id: "blog1", links: [{ href: "", text: "Link one goes to some other section" }] },
  { title: TITLE_2, paragraph: YOUTUBE_TEXT, id: "blog2", links: [{ href: "", text: "Link one goes to some other section" }] },
  { title: TITLE_3, paragraph: ONLINE_SURVEYS, id: "blog3", links: [{ href: "", text: "Link one goes to some other section" }] },
  { title: TITLE_4, paragraph: SELL_WEBSITES, id: "blog4", links: [{ href: "", text: "Link one goes to some other section" }] },
  { title: TITLE_5, paragraph: TEST_APPS, id: "blog5", links: [{ href: "", text: "Link one goes to some other section" }] },
  { title: TITLE_6, paragraph: FREELANCE_WORK, id: "blog6", links: [{ href: "", text: "Link one goes to some other section" }] },
  { title: TITLE_7, paragraph: SELL_PHOTOGRAPHY, id: "blog7", links: [{ href: "", text: "Link one goes to some other section" }] },
  { title: TITLE_8, paragraph: TEACH_ONLINE, id: "blog8", links: [{ href: "", text: "Link one goes to some other section" }] },
  { title: TITLE_9, paragraph: RECORD_PODCAST, id: "blog9", links: [{ href: "", text: "Link one goes to some other section" }] },
  { title: TITLE_10, paragraph: DIGITAL_PRODUCTS, id: "blog10", links: [{ href: "", text: "Link one goes to some other section" }] },
]

const FeaturedContent = () => {
  return (
    <div>
      <HustleList />

      {
        detailedData.map((item, index) => {
          return <DetailedHustle key={index} title={item.title} paragraph={item.paragraph} links={item.links} id={item.id} />
        })
      }
    </div>
  )
}

export default FeaturedContent;