import React from 'react'
import FeaturedContent from '../../../styles/FeaturedContent.css'

const DetailedHustle = ({title, paragraph, links, id}) => {
  return (
    <div id={id} className="detailed-hustle-card shadow-md">
      <h3 className="font-bold text-2xl">{title || "Title"}</h3>
      <p className="text-xl">{paragraph || "Paragragh goes here"}</p>
      <div className='flex flex-col'>
        {
          links.length && links.map((item, index) => {
            return <a className='mt-1' key={index} href={item.href}>{item.text}</a>
          })
        }
      </div>
    </div>
  )
}

export default DetailedHustle;