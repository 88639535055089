import { Link } from "react-router-dom";

const Navbar = () => {
  return (
    <nav className="bg-blue-600 text-white p-4 mb-10">
      <div className="mx-auto flex justify-between items-center">
        <div className="flex flex-row items-center">
          <img src="/images/hp2.png" alt="HustlePig Logo" style={{ width: "60px", borderRadius: "20px" }} />
          <h1 className="text-2xl font-bold justify-center ml-3">HustlePig</h1>
        </div>
        <ul className="flex space-x-4 sm:space-x-6">
          <li>
            <Link to="/" className="hover:underline">
              Calculator
            </Link>
          </li>
          <li>
            <Link to="/guides" className="hover:underline">
              Guides
            </Link>
          </li>
          <li>
            <Link to="/about" className="hover:underline">
              About
            </Link>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
