import React from 'react'
import PageLayout from '../components/PageLayout'

const Guides = () => {
  return (
    <PageLayout title="HustlePig Guides" description="Various guides on side hustles">
      <div className="p-4">Coming Soon: Side Hustle Guides! Wow</div>
    </PageLayout>
  )
}

export default Guides