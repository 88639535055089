import '../styles/PageLayout.css'; // Centralized styles

function PageLayout({ title, description, showDescription, children, pagePadding = 0 }) {
  return (
    <div className="page-layout md:w-1/2 mx-auto" style={{ padding: pagePadding }}>
      <title>{title || 'HustlePig'}</title>
      <meta name="description" content={description || 'Discover the best side hustles with HustlePig.'} />
      <header className="page-header">
        <h1>{title}</h1>
        {
          showDescription &&
          <p className="page-description">
            {description}
          </p>
        }
      </header>
      <main className="page-content">{children}</main>
    </div>
  );
}

export default PageLayout;
