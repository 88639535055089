import { Routes, Route } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import Guides from "./pages/Guides";
import About from "./pages/About";
import HomePage from "./pages/Home";


function App() {
  return (
    <div className="flex flex-col min-h-screen">
      {/* <Header /> */}
      <Navbar />
      <main className="flex-grow">
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/guides" element={<Guides />} />
          <Route path="/about" element={<About />} />
        </Routes>
      </main>
      <Footer />
    </div>
  );
}

export default App;
